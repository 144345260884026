.user-avatar {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 700;
  font-size: 19px;
  transition: 0.5s background-color, 0.5s border-color;
  @media (max-width: 576px) {
    border-width: 2px;
    width: 35px;
    height: 35px;
    font-size: 14px;
  }
}

.dashboard-header--user {
  position: relative;
  height: 100%;
  display: flex;

  .user-menu {
    display: flex;
    align-items: center;
    z-index: 1;
    cursor: pointer;

    .user-name {
      margin: 0 10px;

      .name {
        font-size: 13px;
        transition: 0.5s color;
      }

      .profile {
        display: none;
      }
    }

    .arrow {
      svg {
        width: 10px;
        vertical-align: 3px;
        transition: 0.3s transform, 0.3s stroke;
      }
    }
  }

  .user-nav {

    .nav-item {
    }

    .user-nav--profile {
      display: none;
    }
  }

  &.open {
    .user-menu {
      .arrow {
        svg {
          stroke: #676d72;
          transform: rotate(180deg);
        }
      }
    }

    .user-nav {
      transform: translate3d(0, 0, 0);
    }
  }

  .user-backdrop {
    display: none;
  }
}
