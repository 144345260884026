@import "../src/vars";

.custom-modal {
  display: none;

  .box-modal {
    margin: 15px;
    padding: 25px;
    border-radius: 8px;
    max-width: 50vw;
    position: relative;
    animation: 0.3s showModal 1;
    animation-fill-mode: forwards;
    transform: translateY(-30px);
    opacity: 0;
  }

  .modal-title {
    position: relative;

    h3 {
        text-align:center;
    }

    h5 {
      margin: 0;
      color: white;
      font-size: 25px;
      font-weight: 500;
      text-align: center;
    }

    h4 {
        text-align:center;
    }
  }

  .modal-close-button {
    width: 30px;
    height: 30px;
    background-image: url("../../img/common/img/icon-cancel.svg");
    top: 20px;
    cursor: pointer;
    background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    right: 20px;
    transition: 0.2s opacity;

    &:hover {
      opacity: 0.5;
    }
  }

  .content {

    figure {
      img {
        max-width: 110px;
      }
    }

    p {
      &.important-message {
        color: $c-yellow;
      }
    }

    .modal2fa {
      font-weight: 700;

      a{
        text-decoration: underline;
        color: #676d72;
        &:hover{
          color: #8f9499;
        }
      }
    }

    .w-icon {
      width: max-content;
      padding: .3em 0 .3em 2.5em;
      background-size: 2em;
      background-position: left;
      background-repeat: no-repeat;

      &.-blue-check {
        background-image: url("../../img/common/img/icon-check-blue.svg");
      }
    }

    .copy {
      font-size: 13px;
      color: #454d50;
      margin: 0;

      button {
        margin: 0;
      }
    }
  }

  .modal-icon {
    background-image: url("../../img/common/img/icon-modal-success.svg");
    width: 90px;
    height: 90px;
    background-color: #eaeaea;
    border-radius: 50%;
    position: absolute;
    top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 35px;

    svg,
    img {
      width: 35px;
    }
  }

  // Modals responsive styles

  @media (max-width: 768px) {
    .modal-close-button {
      top: 10px;
      right: 10px;
    }

    .modal-title {
      h5 {
        font-size: 23px;
      }
    }

    .modal-icon {
      top: -30px;
      width: 70px;
      height: 70px;
      background-size: 20px;

      svg,
      img {
        width: 20px;
      }
    }
  }

  // Modal animations

  @keyframes showBackground {

    from {
      background-color: rgba(0, 0, 0, 0);
    }

    to {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  @keyframes hideBackground {

    from {
      background-color: rgba(0, 0, 0, 0.5);
    }

    to {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  @keyframes showModal {

    from {
      transform: translateY(-30px);
      opacity: 0;
    }

    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes hideModal {

    from {
      transform: translateY(0px);
      opacity: 1;
    }

    to {
      transform: translateY(30px);
      opacity: 0;
    }
  }

  // Open and Close Styles

  &.modal-open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    overflow: auto;
    z-index: 500;
    animation: 0.3s showBackground 1 forwards;
    padding-top: 60px;
    padding-bottom: 60px;

    .box-modal {
      animation: 0.3s showModal 1 forwards;
    }
  }

  &.modal-closed {
    animation: 0.3s hideBackground 1;

    .box-modal {
      animation: 0.3s hideModal 1;
    }
  }

  // Modal alerts messages styles

  &.success {

    .modal-title h5 {
      color: #8cc321;
    }
  }

  &.danger {

    .modal-icon {
      background-image: url("../../img/common/img/icon-modal-danger.svg");
    }

    .modal-title h5 {
      color: red;
    }
  }

  &.warning {

    .modal-icon {
      background-image: url("../../img/common/img/icon-modal-alert.svg");
    }

    .modal-title h5 {
      color: #ffa300;
    }
  }

  &.terms-warning {
    .modal-icon {
      background-image: url("../../img/common/img/icon-modal-alert.svg");
    }
 }

  &.exit {

    .modal-icon {
      background-image: url("../../img/common/img/icon-modal-exit.svg");
    }
  }

  &.money {

    .modal-icon {
      background-image: url("../../img/common/img/icon-modal-money.svg");
    }
  }

  &.contact {

    .modal-icon {
      background-image: url("../../img/common/img/icon-modal-contact.svg");
      background-size: 100%;
    }
  }

  &.data {

    .modal-icon {
      background-image: url("../../img/user/img/icon-data.svg");
      background-size: 50%;
      background-position: 58% 50%;
    }
  }

  // Modal for confirmation messages

  &.confirm {

    .modal-icon {
      display: none;
    }

    .modal-title h5 {
      color: #09B1FF;
    }

    @media (max-width: 576px) {

      .terms-and-conditions {
        height: 250px;
      }
    }
  }

  // Modal for Email call to action

  &.email {
    font-size: 1em;

    .modal-icon {
      display: none;
    }

    .box-modal {
      padding: 2em 3em;
    }

    .modal-title {
      position: absolute;
      width: 50%;
      top: 9em;
      right: 0;
      text-align: left;
      padding-right: 3em;

      h5 {
        text-align: left;
        color: #09B1FF;
        font-size: 2.5em;
      }

      h4 {
        text-align: left;
        color: #09B1FF;
        font-size: 1.2em;
      }
    }

    .modal-illus {
      width: 47%;

      img {
        width: 100%;
      }
    }

    .modal-box {
      margin: 2em 0 1em;
      padding: 0 4em;

      .w-icon {
        margin: 0 auto;
      }

      button {
        margin-top: 2em;
      }
    }
  }

  // Modal for Investments explanation

  &.investments {

    .modal-icon {
      display: none;
    }

    .modal-title h5 {
      text-align: left;
    }

    .modal-box {
      text-align: left;
    }
  }

    .confirmation-token-notice {
        .token-digit-input{
            width: 2em;
            margin: 0;
            padding: 0;
        }
    }
}
