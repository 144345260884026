@import "../../src/vars";

.my-activity--filter {
  position: relative;

  &.open-dropdown {
    .filter-content {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);
    }
  }

  .btn-filter {
    margin-bottom: 15px;
    color: #FFC0EF;
    border: 0;
    background: transparent;
    padding: 0 0 0 25px;
    margin: 0;
    outline: none;
    background-image: url("../../../img/dashboard/my-activity/img/icon-adjustments.svg");
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: left center;
  }

  .filter-content {
    padding: 10px 20px;
    position: absolute;
    right: 0;
    width: 350px;
    z-index: 200;
    border-radius: 5px;
    top: calc(100% + 15px);
    opacity: 0;
    pointer-events: none;
    transform: translateY(15px);
    transition: 0.2s opacity, 0.2s transform;

    header {
      padding: 20px 10px;
      cursor: pointer;
      position: relative;

      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        right: 0;
        top: 25px;
        width: 20px;
        height: 15px;
        background-repeat: no-repeat;
        background-image: url("../../../img/dashboard/my-activity/img/icon-dropdown-blue.svg");
        transition: .2s transform;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    .collapse-main {
      border-bottom: 1px solid #eaeaea;

      &:last-child {
        border-bottom: 0;
      }

      &.open {
        header {
          &:before {
            transform: rotate(180deg);
          }
        }
      }
    }

    .collapse-content {
      padding-left: 15px;
      padding-top: 5px;
      padding-bottom: 20px;
    }

    .content {
        span {
            font-size: 12px;
        }

        input {
            margin-bottom: 10px;
            border: 1px solid transparent;
            border-radius: 4px;
            padding: 0 10px;
            outline: none;

        &:active,
        &:focus {
          border-color: $c-accent;
        }
      }
    }
  }
}
