@import "../../src/vars";

.deposits-in-pesos {
  display: flex;
  align-items: flex-start;

  .data-main {
    margin-top: 30px;
    margin-right: 10px;
    width: 100%;

    .data-tabs {
      display: flex;
      align-items: center;

      .tab {
        border-radius: 5px 5px 0 0;
        border: none;
        background-color: transparent;
        color: #a6adb5;
        padding: 15px 20px;
        cursor: pointer;
        margin-right: 5px;
        font-size: 14px;
        font-weight: 600;
        transition: 0.2s background-color;
        position: relative;

        &.active {
          color: #09B1FF;

          &:before {
            opacity: 1;
          }
        }

        &:focus {
          outline: none;
        }

        &:before {
          content: "";
          background-color: #09B1FF;
          height: 2px;
          width: calc(100% - 20px);
          display: inline-block;
          position: absolute;
          left: 10px;
          right: 10px;
          bottom: 0;
          opacity: 0;
          transition: 0.2s opacity;
        }
      }
    }

    .data-content {
      padding: 30px;
    }
  }

  @media (max-width: 992px) {
    .data-main {
      .data-tabs {
        .tab {
          width: 50%;
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .data-main {
      margin-top: 0;
    }
  }
}
