.my-activity {
  margin-top: 40px;

  .my-activity--box {
  }

  .my-activity--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title-mini {
    font-size: 12px;
    color: #a6adb5;
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }
}
