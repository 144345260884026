@import "../src/vars";

.select-container {
    position: relative;

    &.open-dropdown {
        .dropdown {
            opacity: 1;
            transform: translateY(0px);
            pointer-events: auto;
        }

        .selector {
            &:before {
                transform: rotate(180deg);
            }
        }
    }

    .selector-error {
        font-size: 12px;
        font-weight: bold;
        color: #d42222;
    }

    .dropdown {
        border-radius: 5px;
        z-index: 100;
        position: absolute;
        left: 0;
        right: 0;
        opacity: 0;
        transform: translateY(-20px);
        pointer-events: none;
        transition: 0.3s opacity, 0.3s transform;
        max-height: 240px;
        overflow-y: auto;

        .item {
            display: flex;
            align-items: center;
            padding: 10px;
            transition: 0.2s background-color;
            cursor: pointer;

            .item-image {
                flex-shrink: 0;
            }

            .item-text {
                text-align: left;
            }

            .item-image img {
                width: 40px;
                margin-right: 10px;
            }
        }
    }

    .selected-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        transition: 0.2s background-color;
        cursor: pointer;

        .item-image img {
            width: 40px;
            margin-right: 10px;
        }
    }

    .selector {
        position: relative;
        width: 100%;
        outline: none;
        padding: 0 35px 0 10px;

        /* &:before {
            content: "";
            display: inline-block;
            background-repeat: no-repeat;
            background-image: url("../../img/common/img/icon-arrow-down.svg");
            cursor: pointer;
            position: absolute;
            transition: 0.3s transform;
            right: 10px;
        } */

        &.disabled {
            pointer-events: none;

            &:before {
                background: none;
            }
        }
    }

    &.buy-sell-select {
        height: 37px;
        margin-left: 23px;
        border-radius: 40px;
        display: inline-flex;
        align-items: center;

        .selector {
            padding: 0 35px 0 16px;
            margin-bottom: 0;
            border-bottom: 0;

            &:before {
                width: 12px;
                height: 6px;
                right: 12px;
                top: 17px;
            }

            .item-text {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
            }

            .selected-item {
                justify-content: left;
            }
        }

        .dropdown {
            top: 46px;

            .item {

                .item-text {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;
                }

                &.selected {
                    .item-text {
                        font-weight: bold;
                    }
                }

            }
        }
    }
}

.header-select {
    position: relative;
    margin: 0em 2em 0em 3em;

    @media (max-width: 768px) {
        display: none;
    }

    &.open-dropdown {
        .dropdown {
            opacity: 1;
            transform: translateY(10px);
            pointer-events: auto;
        }

        .selector {
            &:before {
                transform: rotate(180deg);
            }
        }
    }

    .selector-error {
        font-size: 12px;
        font-weight: bold;
        color: #d42222;
    }

    .dropdown {
        border-radius: 5px;
        z-index: 100;
        position: absolute;
        left: 0;
        right: 0;
        opacity: 0;
        transform: translateY(-20px);
        pointer-events: none;
        transition: 0.3s opacity, 0.3s transform;
        max-height: 240px;
        overflow-y: auto;
        box-shadow: 0px 4px 14px rgba(33, 23, 72, 0.08);
        font-size:14px;

        .item {
            display: flex;
            align-items: center;
            padding: 10px;
            transition: 0.2s background-color;
            cursor: pointer;

            .item-image {
                flex-shrink: 0;
            }

            .item-text {
                text-align: left;
            }

            .item-image img {
                width: 40px;
                margin-right: 10px;
            }

            &.selected {
                .item-text {
                    font-weight: bold;
                }
            }
        }
    }

    .selected-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 1px 0;
        transition: 0.2s background-color;
        cursor: pointer;

        .item-image img {
            width: 40px;
            margin-right: 10px;
        }
    }

    .selector {
        position: relative;
        width: 100%;
        outline: none;
        min-width: 10.5em;
        font-size: 14px;
        padding: 0 35px 0 18px;
        border-bottom: 0;

        &:before {
            content: "";
            width: 10px;
            height: 6px;
            display: inline-block;
            background-repeat: no-repeat;
            background-image: url("../../img/common/img/icon-arrow-down.svg");
            cursor: pointer;
            position: absolute;
            transition: 0.3s transform;
            right: 14px;
            top: 11px;
            filter: grayscale(1) brightness(100);
        }
    }
}
