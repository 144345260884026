@import '../../../../node_modules/bootstrap/scss/bootstrap';
@import "../../../../node_modules/slick-carousel/slick/slick.css";
// @import "~slick-carousel/slick/slick-theme.css";
@import "../src/vars";
@import "../src/elements";

*,
::after,
::before {
  box-sizing: border-box;
}

/* Outdated browser rework */
div#outdated {
  text-transform: none !important;
  background: linear-gradient(to right, #0D75D1, #09B1FF) !important;
  padding: 0 24px 24px !important;

  p.last {
    display: none;
  }

  a#updateLink {
    color: white;
    font-weight: bold;
    text-decoration: underline;
    transition: 0.3s transform, 0.3s color;

    &:hover {
      color: #c9d2e0 !important;
    }
  }

  a#buttonUpdateBrowser {
    background-color: #0e7dcc !important;
    margin: 20px auto 0 !important;

    &:hover {
      color: #c9d2e0 !important;
    }
  }
}

.spinner-color {
  color: #ffabea;
}

img {
  max-width: 100%;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: sans-serif; */
  width: 100%;
  /* font-family: $f-nunito; */
  /* color: $c-text; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $c-title;
  font-weight: 600;
}

a {
  transition: 0.2s color;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.logo {
  margin-bottom: 30px;
}

.nav-bar {
  position: fixed;
  background-color: white;
  z-index: 99999;
  bottom: 0;
  left: 0;
  padding: 50px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  transform: translateX(-90%);
  transition: 0.3s transform, 0.3s opacity;
  will-change: transform;
  opacity: 0.5;

  &:hover {
    transform: translateX(0%);
    opacity: 1;
  }

  a {
    color: orange;
    font-weight: 900;
    text-decoration: none;
    font-size: 15px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.slick-dots {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 5px;

    button {
      width: 15px;
      height: 15px;
      display: inline-block;
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 0;
      border-radius: 50%;
      background-color: #a6adb5;
      cursor: pointer;
      outline: none;
      transition: 0.2s background-color;
    }

    &.slick-active {
      button {
        background-color: #8cc321;
      }
    }
  }
}

.slick-slide,
.slick-slide * {
  outline: none !important;
}

.modal-test {
  min-height: 100vh;
}

.icon-copy {
  width: 1.2em;
  fill: #545454;
  display: inline-block;
  margin: 0 0 0 8px;
  position: relative;
  bottom: 1px;
  cursor: pointer;

  &:hover {
    fill: #000000;
    transition: 0.3s;
  }

  &.big {
    width: 1.6em;
  }
}

.copy-link {
  color: $c-accent-light;
  cursor: pointer;
}

@media (max-width: 768px) {
  .dashboard-title {
    display: none;

    &.show-always {
      display: block;
    }
  }

  /* INTERCOM */
  .intercom-lightweight-app-launcher, .intercom-launcher-frame {
    left: inherit !important;
    right: 20px !important;
    bottom: 70px !important;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

/* IMAGE PRELOADING */

// This declares a list variable to store all images to preload
// Use case example: we need these images to show the "network error" modal with its images
$preloaded-images: (url("../../img/common/img/icon-modal-alert.svg") url("../../img/common/img/icon-cancel.svg"));

body {
  &:after {
    display: none;
    content: $preloaded-images;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
