@import "../src/vars";
$responsiveness-breakpoint: 576px;
$vertical-padding: 32px;
$horizontal-padding: 16px;
$accent-color: #0865FA;

.link-btn {
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
}

.title-block {
    margin-top: 32px;
}

.title {
    font-size: 18px;
    color: $c-title;
    font-weight: bold;
    margin: 0;
}

.subtitle {
    font-size: 18px;
    color: $c-title;
    line-height: 18px;
    margin: 0;
}

.text {
    font-weight: bold;
    font-size: 14px;
    color: $c-neutrals-gray-1;
}

.oauth-authorization-request {
    .confirmation-token-notice {
        padding: $vertical-padding $horizontal-padding;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        height: 100vh;

        @media (max-width: $responsiveness-breakpoint) {
            .input-confirmation-token-header{

                margin-top: auto;
            }

            .input-text {
                margin-bottom: auto;
            }
        }

        .token-digit-input {
            height: 48px;
            width: 42px;
            padding: 0;
            margin: 24px 6px 0 6px;
            border-radius: 8px;
            box-shadow: 0 1px 10px rgba(33, 23, 72, 0.08);
            border: none;
            box-sizing: border-box;
        }

        .token-digit-input:focus {
            border: 1px solid $accent-color;
        }
    }
}

.oauth-authorization {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $responsiveness-breakpoint) {
        flex-grow: 1;
    }

    .information-block {
        .text-block {
            padding: 0 0 12px 0;

            .permissions-block {
                margin: 24px 0;
                list-style: inside; // Includes bullets in text alignment and justification.
                padding: 0;

                .permission {
                    @extend.text;
                    margin: 4px 0;
                }
            }

            .revoke-permissions-link {
                font-size: 14px;
                color: $c-grey;
                text-decoration: underline;
            }
        }

        .connection-image {
            .logo {
                margin: 0 7px;
                height: 46px;
                width: 46px;
                border-radius: 10px;
            }

            .arrows {
                margin: 0 7px;
                height: 25px;
            }
        }
    }

    .authorization-terms-and-conditions {
        font-size: 12px;

        .terms-and-conditions-link{
            text-decoration: underline;
        }
    }
}

.action-buttons {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    width: 300px;

    @media (max-width: $responsiveness-breakpoint) {
        width: 100%;
    }

    .cta-button {
        margin-bottom: 20px;
        font-size: 16px;
        height: 48px;
        padding: 10px;
        width: 100%;
        background-color: $accent-color;
    }

    .link-btn {
        font-size: 16px;
        font-weight: bold;
        color: $accent-color;
    }
}

.unable-to-authorize {
    padding: $vertical-padding $horizontal-padding;
    display: flex;
    flex-direction: column;
    height: 100vh;
    text-align: center;

    @media (min-width: $responsiveness-breakpoint) {
        justify-content: center;
    }


    .information-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }

    .unable-to-authorize-image {
        width: 300px;
        @media (max-width: $responsiveness-breakpoint) {
            width: 150px;
        }
    }

    .title {
        margin-bottom: 12px;
    }
}
