$f-nunito: 'Nunito', sans-serif;

$c-text: #494e51;
$c-grey: #676d72;
$c-title: #2c3033;
$c-accent: #0D75D1;
$c-accent-light: #09B1FF;
$c-yellow: #EFA003;
$c-red: #E50000;
$c-peso: #07B0FF;
$c-dai: #7D3775;
$c-btc: #FF5B56;
$c-eth: #6985E8;
$c-dollar: #368682;
$c-success: #02c575;

$c-neutrals-gray-1: #647892;

@mixin currenciesColors {
    &.ars {
        color: $c-peso
    }
    &.dai {
        color: $c-dai
    }
    &.btc {
        color: $c-btc
    }
    &.eth {
        color: $c-eth
    }
    &.usd {
        color: $c-dollar
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }
    &:-moz-placeholder {
        @content
    }
    &::-moz-placeholder {
        @content
  }
  &:-ms-input-placeholder {
    @content
  }
}
