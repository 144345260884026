@import "../../src/vars";

.buy-and-sell-title {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        margin-top: 32px;
        margin-bottom: 17px;
        font-size: 16px;
    }
}

.buy-and-sell {
    display: flex;
    align-items: flex-start;

    .buy-and-sell-main {
        margin-top: 30px;
        margin-right: 10px;
        width: 100%;

    .buy-and-sell-content {
        padding: 30px;
        border-radius: 5px;
    }
  }

  @media (max-width: 768px) {
      .buy-and-sell-main {
          margin-top: 0;
      }
  }
}
