.input-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .checkbox {
    width: 19px;
    height: 19px;
    margin-right: 8px;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s background-color, 0.2s border-color;

    svg {
      fill: #141414;
      width: 12px;
      opacity: 0;
      transform: scale(0.7);
      transition: 0.2s opacity, 0.2s transform;
    }
  }

  &.active {

    svg {
      opacity: 1;
      transform: scale(1);
    }
  }
}
