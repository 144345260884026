@import "../src/vars";

.alert {
  margin-bottom: 1px !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 8px;

  .alert-content {
    margin: 0;
    display: flex;
    align-items: flex-start;

    p {
        color:inherit!important;
        font-size:14px;
      margin: 0;
    }

    .alert-icon {
      width: 1em;
      margin: 0 .8em 0 0;
    }
  }

  button {
    color: $c-peso;
    background: none;
    border: none;
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: none;
      text-decoration: none;
    }
  }
}
