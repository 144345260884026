// main btn
$btn-fill-color: #013327;
$btn-text-color: white;
// alt btn
$alt-btn-border: inset 0 0 0 2px $btn-fill-color;
$alt-btn-text-color: $btn-fill-color;
// disable
$btn-disable-fill-color: #E5ECEB;
$btn-disable-border: inset 0 0 0 2px $btn-disable-fill-color;
$btn-disable-text-color: #696468;
// shadow for btn
$btn-shadow-step1: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
$btn-shadow-step2: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
$btn-shadow-step3: 0 6px 10px 0 rgba(0, 0, 0, 0.2);

.basic-button-style {
  display: inline-block;
  background-color: $btn-fill-color;
  color: $btn-text-color;
  border-radius: 5px;
  font-size: 1em;
  line-height: 1em;
  padding: 1em 2em;
  text-align: center;
  text-decoration: none;
  text-transform: initial;
  font-weight: 600;
  outline: none !important;
  border: none;
  cursor: pointer !important;
  transition: 0.2s transform, 0.2s box-shadow, 0.2s background-color;
}

// CALL TO ACTION button style

.cta-button {
  @extend .basic-button-style;
  border-radius: 1.5em;
  box-shadow: $btn-shadow-step1;

  &:hover {
    background-color: lighten($btn-fill-color, 5%);
    box-shadow: $btn-shadow-step2;
  }

  &:focus {
    background-color: lighten($btn-fill-color, 10%);
    box-shadow: $btn-shadow-step2;
  }

  &:active {
    background-color: lighten($btn-fill-color, 15%);
    box-shadow: $btn-shadow-step3;
  }

  &.disabled {
    background-color: $btn-disable-fill-color;
    color: $btn-disable-text-color;
    pointer-events: none;
    cursor: not-allowed;

    &:hover {
      cursor: auto !important;
    }

    &:active {
      transform: none;
    }
  }
}

// PRIMARY button style

.simple-button {
  @extend .basic-button-style;
  border-radius: 16px;
  width: 250px;
  background-color: #05A7FF;
}

.simple-secondary-button {
  @extend .basic-button-style;
  border-radius: 16px;
  width: 250px;
  background-color: rgba(5, 167, 255, 0.1);
  color: #05A7FF;
}

.primary-button {
  @extend .basic-button-style;

  &:hover {
    background-color: lighten($btn-fill-color, 5%);
    box-shadow: $btn-shadow-step1;
  }

  &:focus {
    background-color: lighten($btn-fill-color, 10%);
    box-shadow: $btn-shadow-step1;
  }

  &:active {
    background-color: lighten($btn-fill-color, 15%);
    box-shadow: $btn-shadow-step2;
  }

  &.disabled {
    background-color: $btn-disable-fill-color;
    color: $btn-disable-text-color;
    pointer-events: none;

    &:hover {
      cursor: auto !important;
    }

    &:active {
      transform: none;
    }
  }
}

// SECONDARY button style

.secondary-button {
  @extend .basic-button-style;
  background-color: transparent;
  box-shadow: $alt-btn-border;
  color: $alt-btn-text-color;

  &:hover {
    background-color: lighten($btn-fill-color, 40%);
    color: $alt-btn-text-color;
  }

  &:focus {
    background-color: lighten($btn-fill-color, 40%);
    color: $alt-btn-text-color;
  }

  &:active {
    background-color: lighten($btn-fill-color, 35%);
  }
}

// TERTIARY button style

.tertiary-button {
  @extend .basic-button-style;
  background-color: transparent;
  color: $btn-disable-text-color;

  &:hover {
    background-color: lighten($btn-fill-color, 40%);
    color: $alt-btn-text-color;
  }

  &:focus {
    background-color: lighten($btn-fill-color, 40%);
    color: $alt-btn-text-color;
  }

  &:active {
    background-color: lighten($btn-fill-color, 35%);
  }
}

// OTHER buttons style (

.main-button-upload {
  background-color: #09b1ff;
  color: #fff;
  display: inline-flex;
  height: 45px;
  padding: 0 20px 0 50px;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  outline: none !important;
  border: 0;
  cursor: pointer !important;
  transition: 0.2s transform, 0.2s color, 0.2s background-color;
  background-image: url("../../img/common/img/icon-upload.svg");
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: 20px center;

  &:hover {
    background-color: #008eea;
  }
}

.main-button-border {
  background-color: transparent;
  color: #0D75D1;
  box-shadow: inset 0 0 0 1px #09B1FF;
  display: inline-flex;
  height: 50px;
  padding: 0 30px;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  outline: none !important;
  border: 0;
  cursor: pointer !important;
  transition: 0.2s transform, 0.2s color, 0.2s background-color;

  &:hover {
    background-color: #09B1FF;
    color: white;
  }
}

.main-button-white {
  background-color: white;
  color: #0D75D1;
  display: inline-flex;
  height: 40px;
  padding: 0 30px;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  outline: none !important;
  border: 0;
  cursor: pointer !important;
  transition: 0.2s transform, 0.2s color, 0.2s background-color;

  &:hover {
    background-color: #09B1FF;
    color: white;
  }
}

.link-btn {
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  outline: none;
  transition: 0.2s color;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    color: darken($c-accent, 20);
  }
}

.add-btn {
  background-color: #2E665B;
  color: white;
  display: inline-flex;
  height: 50px;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  outline: none !important;
  border: 0;
  cursor: pointer !important;
  transition: 0.2s transform, 0.2s color, 0.2s background-color;
  background-image: url("../../img/common/img/icon-add.svg");

  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 20px center;
  padding: 0 25px 0 50px;



  &:hover {
    background-color: #013327;
  }
}

input[type="text"]::placeholder {
  color: #919191;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.button-action-link {
  width: 100%;
  display: block;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

@media (max-width: 992px) {

  .button-group {
    flex-direction: column;
  }

  .button-action-link {
    margin-right: 0;
  }
}
