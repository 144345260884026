.login {
  background-image: url("../../img/login/img/icon-sign-up.svg");
  background-repeat: no-repeat;
  background-size: auto 100%;
  min-height: 100vh;
  display: flex;

  .login-image {
    width: 50%;
  }

  .login-content {
    width: 50%;
    padding: 50px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .logo {
    width: 250px;
  }

  .login-terms-and-conditions {
    margin-top: 50px;
    font-size: 12px;
    font-weight: 500;

    a {
      display: block;
    }
  }

  .alert {
    flex: 1;
    flex-direction: row;
    position: absolute;
    width: 100%;
    text-align: center;

    button{
      text-decoration: none;
    }
  }

  @media (max-width: 992px) {
    background-position: -16% 0%;
    background-size: auto 80%;
  }
  @media (max-width: 768px) {
    background: none;
    flex-direction: column;
    h1 {
      margin: 0;
      font-size: 1.8rem;
    }
    .login-terms-and-conditions {
      a {
        display: inline-block;
      }
    }
    .logo {
      display: none;
    }
    .login-image {
      display: none;
    }
    .login-content {
      width: 100%;
      padding: 50px 20px;
    }
  }
}

.secure-site-warning{
  display: block;
  width: 100%;
  z-index: 501;
  color: white;
  font-size: 15px;
  padding: 5px;
  text-align: center;
  background-color: #22b4fe;
  position: relative;

  .secure-site-warning-close{
    width: 11px;
    height: 11px;
    position: absolute;
    right: 22px;
    top: 4px;
    cursor: pointer;
    transition: 0.2s opacity;

    &:hover {
      opacity: 0.5;
    }

    @media (max-width: 992px) {
      top: calc(50% - 8.5px);
    }
  }
  @media (max-width: 992px) {
    padding: 10px 50px;
    font-size: 14px;
    line-height: 1.2em;
  }
}

  #partitioned-input {
    margin: 0 27px 10px 40px;
    width: 227px;
    background-image: linear-gradient(to left, #a9a9a9 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 37px 2px;
    background-repeat: repeat-x;
    background-position-x: 35px;

    input {
      padding-left: 15px;
      letter-spacing: 28px;
      border: 0;
      width: 250px;
      margin-bottom: 10px;
      outline: none;
    }
  }

  .custom-modal .content .totp-error h2, .custom-modal .content .totp-error p{
    color: red;
    font-size: .8em;
    max-width: 300px;
    padding-top: 5px;
  }

  .custom-modal .content .totp-error h2 {
    font-size: 1em;
  }

  .custom-modal .content .spinner-color.spinner-border {
    margin: 12px 0;
  }
  .custom-modal .content .cta-button {
    margin-bottom: 0;
  }

  .custom-modal {
    .authentication {
      margin: 0 auto;
      max-width: 500px;

      .modal-box {
        &--text-description {
          color: #909294;
          font-size: 14px;
        }
        &--text-counter {
          color: #909294;
          font-size: 16px;
        }
      }
      .not-device-list {
        list-style-position: inside;
        padding: 0;

        li {
          margin: 10px 0;
        }
      }
    }
  }

  .verification-email-landing {
    display: flex;
    height: 100vh;

    img.verification-email-logo {
      width: 200px;
      display: block;
      margin: 32px auto;
    }

    .verification-email-landing-container {
      margin: 0 auto;

      .verification-email-button button{
        margin: 24px 0 48px;
      }

      .verification-email-qr{
        margin: 48px auto;
        width: 144px;

        img {
          margin-bottom: 48px;
        }
      }

      .verification-email-landing-container-box{
        margin: 0 auto;
        max-width: 80%;
        text-align: center;
        background-color: #F7F9FC;
        border-radius: 15px;

        h2 {
          font-size: 1.5rem;
          padding: 0 16px;
        }

        p {
          font-size: 1.2em;
          color: #646D84;
          max-width: 400px;
          margin: 16px auto;
          padding: 0 8px;
        }

        img.verification-email-check {
          width: 92px;
          margin: 32px;
        }
      }
    }

    .verification-email-landing-container.verification-email-desktop {
      min-width: 900px;

      h2 {
        font-size: 2rem;
      }
    }
  }
